* {
  margin: 0;
  padding: 0;
}

.fas.fa-star {
  color: #ffc107;
  padding: 0 5px;
}

.header {
  background: #fff;
  h1 {
    color: #2b0548;
    display: inline-block;
    vertical-align: bottom;
    padding-left: 10px;
    font-size: 32px;
  }
  a {
    margin: auto;
    text-decoration: none;
  }
}

body {
  overflow-x: hidden;
  background: transparent;
  font-family: "Raleway", sans-serif !important;
  width: 100%;
}

.homeContainer {
  .contact {
    .row {
      .message {
        border-radius: 5px;
        // background: #000;
        // opacity: 0.9;
        text-align: center;
        color: #fff;
        padding: 20px;
        a {
          color: #1a7b33 !important;
        }
        h1 {
          padding-bottom: 10px;
          color: #1a7b33;
        }
        p {
          font-size: 16px;
        }
      }
    }
    .infoContainer {
      .fas {
        color: #1a7b33;
        padding: 20px 0;
      }
      .row {
        .col-lg-6 {
          padding: 30px;
          text-align: center;
          h2 {
            color: #2b0548;
            font-weight: bold;
          }
          p {
            text-align: left;
          }
        }
      }
    }
    .coverContainer {
      background: url("./houses.jpg") no-repeat left bottom fixed;
      -webkit-background-size: cover;
      -moz-background-size: cover;
      -o-background-size: cover;
      background-size: cover;
    }
  }
}
.imgCover {
  background: url("./housepicture.jpg") no-repeat center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  height: 50vh;
}

footer {
  color: #2b0548;
  background: #fff;
  // position: absolute;
  // bottom: 0;
  height: 60px;
  line-height: 60px;
}
